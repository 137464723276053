import { ComponentType, useEffect } from "react"

// Code override to style <pre> blocks, ensure text wrapping, and add copy button inside <pre>
export function withPreWrapAndCopyInside(
    Component: ComponentType
): ComponentType {
    return (props) => {
        useEffect(() => {
            const preBlocks = document.querySelectorAll("pre")

            preBlocks.forEach((block) => {
                // Apply initial styles to <pre> block
                ;(block as HTMLElement).style.whiteSpace = "pre-wrap"
                ;(block as HTMLElement).style.wordWrap = "break-word"
                ;(block as HTMLElement).style.overflowX = "hidden"
                ;(block as HTMLElement).style.width = "100%"
                ;(block as HTMLElement).style.position = "relative" // For positioning the button inside

                // Add a top bar with a "Copy" button inside the <pre> block
                const topBar = document.createElement("div")
                topBar.style.position = "absolute"
                topBar.style.top = "0"
                topBar.style.right = "0"
                topBar.style.padding = "8px"
                topBar.style.backgroundColor = "transparent" // Transparent background to match <pre>
                topBar.style.zIndex = "1" // Ensure it stays on top
                topBar.style.display = "flex"
                topBar.style.justifyContent = "flex-end"
                topBar.style.alignItems = "center"

                // Add the "Copy" button
                const copyButton = document.createElement("button")
                copyButton.innerText = "Copy"
                copyButton.style.background = "#007bff"
                copyButton.style.color = "#fff"
                copyButton.style.border = "none"
                copyButton.style.padding = "5px 10px"
                copyButton.style.borderRadius = "4px"
                copyButton.style.cursor = "pointer"

                // Copy code functionality
                copyButton.addEventListener("click", () => {
                    const codeContent = block.innerText
                    navigator.clipboard.writeText(codeContent).then(() => {
                        copyButton.innerText = "Copied!"
                        setTimeout(() => (copyButton.innerText = "Copy"), 2000)
                    })
                })

                topBar.appendChild(copyButton)
                block.appendChild(topBar) // Add the top bar inside the <pre> block

                // MutationObserver for dynamic content
                const observer = new MutationObserver(() => {
                    const allDivs = block.querySelectorAll("div")
                    allDivs.forEach((div) => {
                        const divEl = div as HTMLElement
                        divEl.style.maxWidth = "100%"
                        divEl.style.overflowX = "hidden"
                    })

                    const cmLineElements = block.querySelectorAll(".cm-line")
                    cmLineElements.forEach((cmLine) => {
                        const cmLineEl = cmLine as HTMLElement
                        cmLineEl.style.whiteSpace = "pre-wrap"
                        cmLineEl.style.wordWrap = "break-word"
                        cmLineEl.style.overflowWrap = "break-word"
                        cmLineEl.style.overflowX = "hidden"
                        cmLineEl.style.maxWidth = "100%"
                    })
                })

                observer.observe(block, { childList: true, subtree: true })
            })
        }, [])

        return <Component {...props} />
    }
}
